import React, { useState, useRef, useEffect } from 'react';
import { Slider, Button, Typography, Box } from '@mui/material';

const BinarySearchGame = () => {
  const initialValues = [1, 50, 100];
  const [values, setValues] = useState(initialValues);
  const [attempts, setAttempts] = useState(0);
  const sliderRef = useRef(null);

  const handleChange = (event, newValues) => {
    setValues(newValues);
  };

  const incrementAttempts = () => {
    setAttempts(attempts + 1);
  };

  const resetAttempts = () => {
    setAttempts(0);
    setValues(initialValues);
  };

  const calculateYellowPosition = () => {
    const [blue, , green] = values;
    return Math.floor((green - blue) / 2 + blue);
  };

  const marks = [
    { value: 0, label: '0' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 40, label: '40' },
    { value: 50, label: '50' },
    { value: 60, label: '60' },
    { value: 70, label: '70' },
    { value: 80, label: '80' },
    { value: 90, label: '90' },
    { value: 100, label: '100' },
  ];

  const handleMouseDown = (index) => (event) => {
    event.preventDefault();
    const startX = event.clientX;
    const startValue = values[index];

    const handleMouseMove = (moveEvent) => {
      if (!sliderRef.current) return;
      const rect = sliderRef.current.getBoundingClientRect();
      const newX = moveEvent.clientX - rect.left;
      const newValue = Math.round(Math.max(0, Math.min(100, (newX / rect.width) * 100)));
      
      const newValues = [...values];
      newValues[index] = newValue;
      if (index !== 1) {  // Solo actualiza automáticamente si se mueve azul o verde
        newValues[1] = calculateYellowPosition();
      }
      setValues(newValues);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    // Actualiza la posición del círculo amarillo cuando cambian los valores de azul o verde
    if (values[0] !== initialValues[0] || values[2] !== initialValues[2]) {
      const newValues = [...values];
      newValues[1] = calculateYellowPosition();
      setValues(newValues);
    }
  }, [values[0], values[2]]);

  return (
    <Box sx={{ width: '100%', padding: '0 20px', boxSizing: 'border-box' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Juego de Búsqueda Binaria RobotGenio
      </Typography>
      
      <Box sx={{ mt: 6, height: 120, position: 'relative' }} ref={sliderRef}>
        <Slider
          value={values}
          onChange={handleChange}
          min={0}
          max={100}
          step={1}
          marks={marks}
          sx={{
            '& .MuiSlider-rail': { 
              background: '#1976d2',
              opacity: 1,
              height: 8 
            },
            '& .MuiSlider-track': {
              display: 'none',
            },
            '& .MuiSlider-thumb': {
              display: 'none',
            },
            '& .MuiSlider-mark': {
              backgroundColor: '#bfbfbf',
              height: 16,
              width: 2,
              '&.MuiSlider-markActive': {
                opacity: 1,
                backgroundColor: 'currentColor',
              },
            },
            '& .MuiSlider-markLabel': {
              fontSize: '0.875rem',
              fontWeight: 'bold',
            },
          }}
        />
        {values.map((value, index) => (
          <Box
            key={index}
            onMouseDown={handleMouseDown(index)}
            sx={{
              position: 'absolute',
              left: `${value}%`,
              top: index === 1 ? '100%' : '-40px',
              transform: 'translateX(-50%)',
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: index === 0 ? 'blue' : index === 1 ? 'yellow' : 'green',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: index === 1 ? 'black' : 'white',
              fontWeight: 'bold',
              fontSize: 16,
              cursor: 'pointer',
              userSelect: 'none',
              zIndex: 1,
            }}
          >
            {value}
          </Box>
        ))}
      </Box>

      <Typography variant="h6" align="center" gutterBottom sx={{ mt: 4 }}>
        Número de intentos: {attempts}
      </Typography>
      
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
        <Button variant="contained" color="primary" onClick={incrementAttempts}>
          Incrementar intentos
        </Button>
        <Button variant="contained" color="secondary" onClick={resetAttempts}>
          Resetear intentos
        </Button>
      </Box>

      <Typography variant="body1" align="center" sx={{ mt: 4 }}>
        Cálculo para el círculo amarillo: ({values[2]} - {values[0]}) / 2 + {values[0]} = {calculateYellowPosition()}
      </Typography>
    </Box>
  );
};

export default BinarySearchGame;
